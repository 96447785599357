import React, { useState, useEffect, useContext } from 'react'
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap'
import { InputField } from '../components'
import { DialogContext } from '../store/context/DialogContext';

const Config = () => {
  const [newData, setNewData] = useState({})
  const { showMessage } = useContext(DialogContext)

  const getStore = async (id) => {
    try {
      const response = await fetch(`/api/v1/get/config`);
      const responseData = await response.json();
      setNewData(responseData);
    } catch (error) {
    }
  }
  useEffect(() => {
    getStore()
  }, [])

  const saveData = async () => {
    try {
      await fetch('/api/v1/add/config', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(newData)
      });
      showMessage("info", "Saved Successfully")

    } catch (error) {
      console.log('Error sending notification:', error);
    }
  };
  return (
    <div>
      <Card style={{ left: "15%", top: "70px", maxWidth: "1320px" }}>
        <Col className="col-sm-3 mt-4">
          <Label>
          </Label>
        </Col>
        <CardBody>
          <Row>
            <Col className="col-sm-3">
              <Label>
                Express Delivery Product ID
              </Label>
              <InputField placeholder="Enter Product ID" value={newData?.expressDeliveryProductId} onChange={(v) => setNewData({ ...newData, expressDeliveryProductId: v })} />
            </Col>
            <Col className="col-sm-3">
              <Label>
                Express Delivery Variant Id
              </Label>
              <InputField placeholder="Enter Variant Id" value={newData?.expressDeliveryVariantId} onChange={(v) => setNewData({ ...newData, expressDeliveryVariantId: v })} />
            </Col>
            <Col className="col-sm-3">
              <Label>
                Enter IOS Soft Update
              </Label>
              <InputField placeholder="Enter IOS Soft Update" value={newData?.iosSoftUpdate} onChange={(v) => setNewData({ ...newData, iosSoftUpdate: v })} />
            </Col>
            <Col className="col-sm-3">
              <Label>
                Enter IOS Hard Update
              </Label>
              <InputField placeholder="Enter IOS Hard Update" value={newData?.iosHardUpdate} onChange={(v) => setNewData({ ...newData, iosHardUpdate: v })} />
            </Col>
            <Col className="col-sm-3 mt-4">
              <Label>
                Enter Android Soft Update
              </Label>
              <InputField placeholder="Enter Android Soft Update" value={newData?.androidSoftUpdate} onChange={(v) => setNewData({ ...newData, androidSoftUpdate: v })} />
            </Col>
            <Col className="col-sm-3 mt-4">
              <Label>
                Enter Android Hard Update
              </Label>
              <InputField placeholder="Enter Android Hard Update" value={newData?.androidHardUpdate} onChange={(v) => setNewData({ ...newData, androidHardUpdate: v })} />
            </Col>
            <Col className="col-sm-3 mt-4">
              <Label>
                Play Store Url
              </Label>
              <InputField placeholder="Play Store Url" value={newData?.playStoreUrl} onChange={(v) => setNewData({ ...newData, playStoreUrl: v })} />
            </Col>
            <Col className="col-sm-3 mt-4">
              <Label>
                App Store Url
              </Label>
              <InputField placeholder=" App Store Url" value={newData?.appStoreUrl} onChange={(v) => setNewData({ ...newData, appStoreUrl: v })} />
            </Col>
            <Col className="col-sm-3 mt-4">
              <Label>
                Enter Offer Days
              </Label>
              <InputField placeholder=" Enter Offer Days" value={newData?.offerdays} onChange={(v) => setNewData({ ...newData, offerdays: v })} />
            </Col>
            <Col className="col-sm-3 mt-4">
              <Label>
                Notification Days
              </Label>
              <InputField placeholder="Notification Days" value={newData?.notificationDays} onChange={(v) => setNewData({ ...newData, notificationDays: v })} />
            </Col>
            <Col className="col-sm-3 mt-4">
              <Label>
                Minimum Delivery Amount
              </Label>
              <InputField placeholder="Minimum Delivery Amount" value={newData?.minDeliveryAmount} onChange={(v) => setNewData({ ...newData, minDeliveryAmount: v })} />
            </Col>
            <Col className="col-sm-3 mt-4">
              <Label>
                In Development
              </Label>
              <InputField type="checkbox" placeholder="In Development" value={newData?.devMode} onChange={(v) => {
                setNewData({ ...newData, devMode: v })
              }} />
            </Col>
          </Row>

          <Row>
            <Col className="col-sm-3 mt-4">
              <Label>
                Sender Email
              </Label>
              <InputField type="text" placeholder="Sender Email" value={newData?.senderEmail} onChange={(v) => {
                setNewData({ ...newData, senderEmail: v })
              }} />
            </Col>
            <Col className="col-sm-3 mt-4">
              <Label>
                Sender UserName
              </Label>
              <InputField type="text" placeholder="Sender UserName" value={newData?.email_username} onChange={(v) => {
                setNewData({ ...newData, email_username: v })
              }} />
            </Col>
            <Col className="col-sm-3 mt-4">
              <Label>
                Sender Password
              </Label>
              <InputField type="password" placeholder="Sender Password" value={newData?.email_password} onChange={(v) => {
                setNewData({ ...newData, email_password: v })
              }} />
            </Col>
          </Row>
          <Button className='col-sm-1 m-2 mt-4' onClick={saveData}>
            Save Config
          </Button>
        </CardBody>
      </Card>
    </div>
  )
}

export default Config
import React, { useEffect, useState, useContext } from 'react'
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import { Button, Card, CardBody, Label, Row } from 'reactstrap';
import { InputField, Layout } from '../components'
import EditLink from '../components/EditLink';
import PencilLink from '../components/PencilLInk';
import { SelectField } from '../components/SelectField/SelectField';
import { DialogContext } from '../store/context/DialogContext';

const Offers = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [newData, setNewData] = useState({})
  const { showMessage } = useContext(DialogContext)
  const fetchData = async () => {
    try {
      const response = await fetch('/api/v1/offers');
      const responseData = await response.json();
      setData(responseData);
    } catch (error) {
      setError(error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const deleteitem = async (id) => {
    try {
      await fetch(`/api/v1/offers/remove/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`

        },
      });
      fetchData();
    } catch (error) {
      console.log('Error sending notification:', error);
    }
  };
  const saveData = async (url) => {
    try {
      await fetch('/api/v1/offers/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`

        },
        body: JSON.stringify(newData)
      });
      showMessage("info", "Saved Successfully")
      fetchData();
    } catch (error) {
      console.log('Error sending notification:', error);
    }
  };
  const type = [
    {
      label: "Public",
      value: 1
    },
    {
      label: "Recent Customer",
      value: 2
    }
  ]
  const getType = (val) => {
    if (val == 1)
      return "Public"
    if (val == 2)
      return "Recent Customer"
  }
  const getimages = (url) => {
    const linksArray = url?.split(',');
    return linksArray
  }
  const getOffer = async (id) => {
    try {
      const response = await fetch(`/api/v1/offer/${id}`);
      const responseData = await response.json();
      setNewData(responseData);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div>
      <Card style={{ left: "15%", top: "70px", maxWidth: "1320px" }}>
        <CardBody>
          <Row>
            <SelectField data={type} className="col-sm-3" placeholder="Enter Type" value={newData?.type} onChange={(v) => setNewData({ ...newData, type: v.value })} />
            <InputField className="col-sm-1" placeholder="Enter Index" value={newData?.index} onChange={(v) => setNewData({ ...newData, index: v })} />
            <InputField className="col-sm-3" placeholder="Enter Url" value={newData?.url} onChange={(v) => setNewData({ ...newData, url: v })} />
            <Button className='col-sm-1 m-3 mt-4' onClick={saveData}>
              Save Url
            </Button>
            <Button className='col-sm-1 ' style={{ position: "absolute", right: "20px" }} onClick={() => setNewData({})}>
              Add new
            </Button>
          </Row>
        </CardBody>
      </Card>
      <Layout
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>Type</Th>
              <Th>Index</Th>
              <Th>Image</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>

        <Layout.TbodyFull>
          <Tbody>
            {data?.map((offer, id) => (
              <Tr key={id}
              >
                <Td>
                  {getType(offer?.type)}
                </Td>
                <Td>
                  {offer?.index}
                </Td>
                <Td>
                  {getimages(offer?.url)?.map((link, index) => (
                    <div className='mb-4'>
                      <img src={link} height="100px" />
                    </div>
                  ))}
                </Td>
                <Td className="float-start">
                  <EditLink onClick={() => deleteitem(offer?._id)} />
                  <PencilLink onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    getOffer(offer?._id)
                  }} />
                </Td>
              </Tr>
            ))
            }
          </Tbody>
        </Layout.TbodyFull>


      </Layout>
    </div>
  )
}

export default Offers
import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { ENDPOINTS, API_METHODS } from "../../common/utils/Constants";
import { ApiHandler } from "../apiHandler/apiHandler";
import { useLocation, useNavigate } from "react-router-dom";

export const UserContext = React.createContext();

export const UserProvider = (props) => {
  const [authToken, setAuthToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState(null);
  const [cart, setCart] = useState({ items: [], address: {} });
  const navigate = useNavigate();
  const isAdminRef = useRef(false)
  const [corpId, setCorpId] = useState(null);

  //support
  // const serverUrl = process.env.REACT_APP_SOCKET_SERVERURL;
  //socket
  const socketRef = useRef(null);
  //peer
  const peerRef = useRef(null);
  //peerCall
  const supportCallRef = useRef({
    role: undefined,
    whoAmI: undefined,
    otherPeerUser: null,
  });
  const supportCallDataRef = useRef({});

  // -calling Status
  // 	not-connected
  // 	waiting-for-call
  // 	calling
  // 	call-connected

  // -screen sharing Status
  // 	not-stared
  // 	waiting-for-ask
  // 	asking
  // 	screen-share-started

  const [supportCallStatusForUser, setSupportCallStatusForUser] = useState("not-connected");
  const [supportCallStatusForAdmin, setSupportCallStatusForAdmin] = useState("not-connected");
  const [supportCallScreenShareStatus, setSupportCallScreenShareStatus] = useState("not-started");

  const [supportCallStatusForUserSetter, setSupportCallStatusForUserSetter] = useState("not-connected")
  const [supportCallStatusForAdminSetter, setSupportCallStatusForAdminSetter] = useState("not-connected")
  const [supportCallScreenShareStatusSetter, setsupportCallScreenShareStatusSetter] = useState("not-started");

  useEffect(() => {
    setSupportCallStatusForAdmin(supportCallStatusForAdminSetter)
  }, [supportCallStatusForAdminSetter])

  useEffect(() => {
    setSupportCallStatusForUser(supportCallStatusForUserSetter)
  }, [supportCallStatusForUserSetter])

  useEffect(() => {
    setSupportCallScreenShareStatus(supportCallScreenShareStatusSetter)
  }, [supportCallScreenShareStatusSetter])

  //support force disconnect on app close for extra
  useEffect(() => {

    return () => {
      if (socketRef.current) {
        socketRef.current.off();
        socketRef.current = null;
      }
    };
  }, []);

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
 

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("token"));
  }, [localStorage.getItem("token")]);



  const login = async (data) => {
    try {
      const response = await ApiHandler({
        reqParam: data,
        method: API_METHODS.POST,
        endPoint: ENDPOINTS.adminLogin,
      });
      if (response.status === 200) {
        setAuthToken(response.data.token);
        setIsLoggedIn(true);
        navigate("/");
        if (response.data.isAdmin) {
          isAdminRef.current = true;
          setIsAdmin(true);
        }
      } else {
        // history?.push("/");
      }
      //  else {
      //   history?.push("/");
      // }

      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const adminLogin = async (data) => {
    try {
      const response = await ApiHandler({
        reqParam: data,
        method: API_METHODS.POST,
        endPoint: ENDPOINTS.adminLogin,
      });
      // console.log(response)
      if (response.status === 200) {
        setAuthToken(response.data.token);
        setIsLoggedIn(true);
        setIsAdmin(true);
        // navigate("/");
        return response;
      } else {
        // navigate("/");
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getOtp = async (data) => {
    const reqParam = data;
    const method = API_METHODS.POST;
    const endPoint = ENDPOINTS.getOtp;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const signUp = async (data) => {
    const reqParam = data;
    const method = API_METHODS.POST;
    const endPoint = ENDPOINTS.signUp;
    try {
      const response = await ApiHandler({ reqParam, method, endPoint });
      //setAuthToken(response.data?.token);
      // setIsLoggedIn(true);
      navigate("/login");
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const logout = async () => {
    const reqParam = {};
    const method = API_METHODS.GET;
    const endPoint = ENDPOINTS.logout;
    try {
      await ApiHandler({ reqParam, method, endPoint });
    } catch (err) {
      console.log(err);
    }
    setIsLoggedIn(false);
    navigate("/login");
    console.log("calling 3")
    // localStorage.clear();
    setAuthToken(null);
    setIsAdmin(false);
  };

  return (
    <UserContext.Provider
      value={{
        query,
        authToken,
        user,
        setUser,
        setAuthToken,
        isLoggedIn,
        setIsLoggedIn,
        logout,
        login,
        signUp,
        getOtp,
        cart,
        adminLogin,
        setCart,
        isAdmin,
        peerRef,
        socketRef,
        supportCallRef,
        supportCallDataRef,
        supportCallScreenShareStatus,
        setsupportCallScreenShareStatusSetter,
        supportCallStatusForUser,
        setSupportCallStatusForUserSetter,
        supportCallStatusForAdmin,

        setSupportCallStatusForAdminSetter,
        corpId,
      }}
    >
      {props?.children}
    </UserContext.Provider>
  );
};

export const API_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

export const ENDPOINTS = {
  adminLogin: "/api/v1/admin/login",
}
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { UserContext } from "./store/context/userContext";
// import { Routes, BrowserRouter as Router, Route, Navigate } from "react-router-dom"
import { connect } from "react-redux";
import "./components/SuperResponsiveTableStyle.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "toastr/build/toastr.min.css";

import HorizontalLayout from "./components/HorizontalLayout/";
import { authRoutes, userRoutes } from "./routes/allRoutes";
// Import scss
import "./assets/scss/module.scss";
import "./assets/scss/theme.scss";
import { changeTopbarTheme } from "./store/actions";
import { I18nContext } from "./store/context/i18nContext";

const App = (props) => {
  const { isLoggedIn } = useContext(UserContext);
  const { translations, lang } = useContext(I18nContext);
  function getLayout() {
    let layoutCls = HorizontalLayout;
    changeTopbarTheme("dark");
    return layoutCls;
  }

  // const Componen = Dashboard

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Routes>
        {authRoutes.map(({ path, Component }, key) => (
          <Route path={path} element={<Component />} key={key} />
        ))}
        {userRoutes.map(({ path, Component }, key) => (
          <Route
            path={path}
            element={
                  <Layout>
                  {/* {translations[lang] === undefined ||
                    translations[lang] == null ||
                    translations[lang] === {} ? (
                    <SettingUp />
                  ) : ( */}
                    <Component />
                  {/* )} */}
                </Layout>
            
            }
            key={key}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);

// {/* <Switch>
// { authRoutes.map((route, idx) => (
//   <Authmiddleware
//     path={route.path}
//     layout={NonAuthLayout}
//     component={route.component}
//     key={idx}
//     isAuthProtected={false}
//   />
// ))}

// { userRoutes.map((route, idx) => (
//   <Authmiddleware
//     path={route.path}
//     layout={Layout}
//     component={route.component}
//     key={idx}
//     isAuthProtected={true}
//     exact
//   />
// ))}
// </Switch> */}

import React, { useEffect, useState, useContext } from "react";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import { InputField, Layout } from "../components";
import EditLink from "../components/EditLink";
import PencilLink from "../components/PencilLInk";
import { SelectField } from "../components/SelectField/SelectField";
import { DialogContext } from "../store/context/DialogContext";
import ViewReview from "./ViewReview";

const Reviews = () => {
  const [data, setData] = useState(null);
  const [view, setView] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [selectedId, setSelectedId] = useState("");

  const [error, setError] = useState(null);
  const [newData, setNewData] = useState({});
  const { showMessage } = useContext(DialogContext);

  const { showError } = useContext(DialogContext);
  const fetchData = async () => {
    try {
      const response = await fetch("/api/v1/product/reviews");
      const responseData = await response.json();
      setData(responseData);
    } catch (error) {
      setError(error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const onApprove = async (event, id, val) => {
    event.stopPropagation();
    // {
    //   console.log(event.target);
    //   try {
    //     await ;
    //     showMessage("info", "Consultant Approved  Successfully");
    //     // fetchData();
    //   } catch (e) {
    //     showError(e);
    //   }
    // }
  };
  return (
    <div>
      <Layout
        showViewPage={view}
        viewSection={<ViewReview reviews={reviews} selectedId={selectedId} />}
      >
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th>Product Name</Th>
              <Th>Ratings</Th>
              <Th>Reviews</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>
        <Layout.TbodyFull>
          <Tbody>
            {data?.map((ele, id) => (
              <Tr key={id}>
                <Td>{ele?.product_name}</Td>
                <Td>{ele?.rating}</Td>
                <Td className="float-start">
                  <Button
                    onClick={() => {
                      setView(true);
                      setReviews(ele?.reviews);
                      setSelectedId(ele?._id);
                    }}
                  >
                    show Reviews
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Layout.TbodyFull>
      </Layout>
    </div>
  );
};

export default Reviews;

import React from "react";

const EditLink = (props) => {
  return (
    <i
      role="button"
      className="mdi mdi-delete-outline text-danger font-size-18 mx-2"
      onClick={props?.onClick}
      // style={{zIndex : ""}}
    />
  );
};
export default EditLink;

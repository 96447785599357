import React, { Children, useEffect, useState } from "react";
import { Table } from "react-super-responsive-table";
import { ThreeBounce } from "better-react-spinkit";
import "./style.css";
import {
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Button,
  Navbar,
  Nav,
  Label,
} from "reactstrap";

import { Pagination } from "../../components";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

const Layout = (props) => {
  const { showViewPage = false, viewSection,innerView } = props;
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["user"]);

  const [rowsPerPage, setRowPerPage] = useState(
    cookies[`page-${props?.title}`]
  );

  let _actionMenu,
    _theadFull,
    _tbodyFull,
    _detailPageTitle,
    _detailPageBody,
    _detailPageFooter;

  Children.forEach(props?.children, (child) => {
    if (child.type === Layout.ActionMenu) {
      return (_actionMenu = child);
    }
    if (child.type === Layout.TheadFull) {
      return (_theadFull = child);
    }
    if (child.type === Layout.TbodyFull) {
      return (_tbodyFull = child);
    }
    if (child.type === Layout.DetailPageTitle) {
      return (_detailPageTitle = child);
    }
    if (child.type === Layout.DetailPageBody) {
      return (_detailPageBody = child);
    }
    if (child.type === Layout.DetailPageFooter) {
      return (_detailPageFooter = child);
    }
  });

  return (
    <React.Fragment>
      <div className={`page-content`}>
        <Row>
          <Col
            className={`left-scroll-independent col-md-12`}
            style={{ height: props?.layoutForm ? `calc(100vh - 120px)` : "" }}
          >
            <div className=" d-flex">{props?.filterInput}</div>

            <div className=" d-flex justify-content-between">
              <Card
                className="mx-0 pt-1"
                style={{
                  height: props?.layoutForm ? `97%` : "",
                  width: showViewPage ? "40%" : innerView ? "100%" : "86%",
                  left: "15%",
                  top: "20px",
                }}
              >
                {!props.hideTitleBar && (
                  <CardTitle className="pt-3 ps-3 pe-3 pb-0">
                    <div
                      className={`d-flex justify-content-between col-12 mb-0`}
                    >
                      <div className="flex-1"></div>
                      {/* {props?.onAddClick && (
                      <AddButton
                        onClick={() => {
                          props?.onAddClick();
                        }}
                      />
                    )} */}

                      {/* For Template Action Menu */}
                      {props?.customOnAddClick && (
                        <UncontrolledDropdown className="ms-2">
                          <DropdownToggle
                            style={{ padding: "0", color: "transparent" }}
                          ></DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem header>Create Template</DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                props?.customOnAddClick(true);
                              }}
                            >
                              For Upload
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                props?.customOnAddClick(false);
                              }}
                            >
                              For Download
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                      {_actionMenu && (
                        <UncontrolledDropdown className="ms-2">
                          <DropdownToggle
                            color="primary"
                            style={{ padding: "0px 1px", height: 30 }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                color: "primary",
                              }}
                            >
                              <i
                                style={{ fontSize: "24px" }}
                                className="bx bx-dots-horizontal-rounded"
                              />
                            </div>
                          </DropdownToggle>
                          {_actionMenu}
                        </UncontrolledDropdown>
                      )}
                    </div>
                  </CardTitle>
                )}
                <CardBody style={{ height: props?.layoutForm ? `100%` : "" }}>
                  {props?.gridLoading || loading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <ThreeBounce size={20} color="#9F73AB" />
                    </div>
                  ) : (
                    <div
                      className="table-rep-plugin table-responsive mb-0"
                      data-pattern="priority-columns"
                      style={{
                        height: props?.layoutForm ? `calc(100vh - 230px)` : "",
                      }}
                    >
                      <Table className="table mb-2 striped-table">
                        {_theadFull}
                        {_tbodyFull}
                      </Table>
                    </div>
                  )}
                </CardBody>

                {/* {props.onPageChange && (
                <div className="xxxxx">
                  <Pagination
                    page={props?.page}
                    rows={rowsPerPage ? `${rowsPerPage}` : props?.rows}
                    total={props?.total}
                    onChange={async (page, rows) => {
                      setLoading(true);
                      await props.onPageChange(page, rows);
                      setCookie(`page-${props?.title}`, rows, { path: "/" });
                      setRowPerPage(rows);
                      setLoading(false);
                    }}
                  />
                </div>

              )} */}
              </Card>

              {showViewPage ? viewSection : ""}
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

Layout.ActionMenu = (props) => <> {props?.children} </>;
Layout.TheadFull = (props) => <> {props?.children} </>;
Layout.TbodyFull = (props) => <> {props?.children} </>;
Layout.DetailPageTitle = (props) => <> {props?.children} </>;
Layout.DetailPageBody = (props) => <> {props?.children} </>;
Layout.DetailPageFooter = (props) => <> {props?.children} </>;

export default Layout;

import React, { useState, useEffect, useContext } from 'react'
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';
import { InputField, Layout } from '../components';
import PencilLink from '../components/PencilLInk';
import { DialogContext } from '../store/context/DialogContext';

const Stores = () => {
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState({})
  const [pincode, setPincode] = useState([])
  const { showMessage, showError } = useContext(DialogContext)

  const fetchData = async () => {
    try {
      const response = await fetch('/api/v1/stores/grid');
      const responseData = await response.json();
      setData(responseData);
    } catch (error) {
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    setNewData({
      ...newData,
      pincodes: pincode
    })
  }, [pincode])
  const saveData = async (url) => {
    try {
      if (!newData.name || !newData.idx || !newData?.pincodes || newData?.pincodes.length == 0 || !newData?.inventoryPincode) {
        showError("Name, Store Name, Store index, Inventory Pincode and Delivery Pincodes are required")
        return;
      }
      await fetch('/api/v1/add/store', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(newData)
      });
      fetchData();
      showMessage("info", "Saved Successfully")
    } catch (error) {
      console.log('Error sending notification:', error);
    }
  };
  const getStore = async (id) => {
    try {
      const response = await fetch(`/api/v1/store/${id}`);
      const responseData = await response.json();
      setNewData(responseData);
      setPincode(responseData?.pincodes)
    } catch (error) {
    }
  }

  return (
    <div>
      <Card style={{ left: "15%", top: "70px", maxWidth: "80%" }}>
        <CardBody>
          <Button className='col-sm-1 ' style={{ position: "absolute", right: "20px" }} onClick={() => setNewData({})}>
            Add new
          </Button>
          <Row className='mt-4'>
            <Col className='col-sm-3'>
              <Label>
                Store Index
              </Label>
              <InputField placeholder="Enter store Index" value={newData?.idx} onChange={(v) => setNewData({ ...newData, idx: v })} />
            </Col>

            <Col className="col-sm-3">
              <Label>
                Enter Store name
              </Label>
              <InputField placeholder="Enter name" value={newData?.name} onChange={(v) => setNewData({ ...newData, name: v })} />
            </Col>
            <Col className="col-sm-3">
              <Label>
                Hours
              </Label>
              <InputField placeholder="Enter Hours" value={newData?.openingHrs} onChange={(v) => setNewData({ ...newData, openingHrs: v })} />
            </Col>
            <Col className="col-sm-3">
              <Label>
                Inventory Pincode
              </Label>
              <InputField placeholder="Inventory Pincode" value={newData?.inventoryPincode} onChange={(v) => setNewData({ ...newData, inventoryPincode: v })} />
            </Col>

            {
              pincode?.map((ele, i) => (
                <InputField className="col-sm-2 mt-4" key={i} placeholder="Enter new Pincode" value={ele} onChange={
                  (v) =>
                    setPincode(
                      pincode?.map((f, x) => {
                        if (i == x) return v;
                        return f;
                      })
                    )
                } />
              ))
            }
            <Button className='col-sm-2 mt-4' style={{ width: "auto", backgroundColor:"#797979" }} onClick={() => setPincode([...pincode, ""])}>
              Add Pincode
            </Button>


          </Row>
          <Button className='col-sm-1 mt-4' style={{ backgroundColor:"green" }} onClick={saveData}>
            Save Store
          </Button>
        </CardBody>
      </Card>
      <Layout>
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr>
              <Th style={{ width: "12%" }}>Store Index</Th>
              <Th style={{ width: "14%" }}>Name</Th>
              <Th style={{ width: "14%" }}>Opening Hours</Th>
              <Th style={{ width: "10%" }}>Inventory Pincode</Th>
              <Th style={{ width: "70%" }}>Pincodes</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>
        <Layout.TbodyFull>
          <Tbody>
            {
              data?.map((ele, id) => (
                <Tr
                  key={id}
                >
                  <Td>
                    {ele?.idx}
                  </Td>
                  <Td>
                    {ele?.name}
                  </Td>
                  <Td>
                    {ele?.openingHrs}
                  </Td>
                  <Td>
                    {ele?.inventoryPincode}
                  </Td>
                  <Td className="float-start">
                    {ele.pincodes ? ele.pincodes.toString() : ""}
                  </Td>
                  <Td className="float-start mx-4">
                    <PencilLink onClick={() => {
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                      getStore(ele?._id)
                    }} />

                  </Td>
                </Tr>
              ))
            }
          </Tbody>
        </Layout.TbodyFull>
      </Layout>
    </div>
  )
}

export default Stores
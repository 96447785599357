import React from "react";
import { Layout } from "../components";
import { Tbody, Th, Thead, Tr } from "react-super-responsive-table";
import {
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import Checkbox from "../components/Checkbox";
import axios from "axios";

const ViewReview = (props) => {
  console.log(props);
  const onApprove = async (event, reviewId, newStatus) => {
    const id = props?.selectedId;
    event.stopPropagation();
    try {
      const response = await axios.post("/api/v1/approve/review", {
        newStatus,
        reviewId,
        id,
      });

      if (response.status === 200) {
        const updatedReview = response.data;
        console.log("Updated Review:", updatedReview);
      } else {
        console.error("API Request Failed");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error("An error occurred:", error);
    }
  };
  const getValue = (val) => {
    if (val == 1) return "Approved";
    else return "Disapproved";
  };
  return (
    <Card
      className="mt-3 py-4 px-1 "
      style={{ width: "44%", top: "3px", height: "fit-content" }}
    >
      <table className="table table-striped mt-4">
        <thead className="p-2 mt-4">
          <tr className="p-2 mt-4">
            <th>Order Id</th>
            <th>Ratings</th>
            <th>Reviews</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {props?.reviews?.map((ele, i) => (
            <tr className="p-2">
              <td>{ele?.order_id}</td>
              <td>{ele?.rating}</td>
              <td>{ele?.msg}</td>
              <td>
                {ele?.status ? (
                  getValue(ele?.status)
                ) : (
                  <UncontrolledDropdown className="m-2">
                    <DropdownToggle
                      style={{
                        padding: "2px",
                        color: "black",
                        background: "white",
                        borderColor: "rgba(0, 0, 0, 0.33)",
                      }}
                      className="px-2"
                    >
                      Action{" "}
                      <i className="uil-angle-down d-inline-block font-size-13"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-start">
                      <DropdownItem
                        onClick={(event) => {
                          onApprove(event, ele?._id, 1);
                        }}
                      >
                        Approve
                      </DropdownItem>
                      <DropdownItem
                        onClick={(event) => {
                          onApprove(event, ele?.id, 2);
                        }}
                      >
                        Disapprove
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default ViewReview;

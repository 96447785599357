import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../store/context/userContext";
import { DialogContext } from "../../store/context/DialogContext";

import "./layout.scss";

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState('dashboard')
  const handleClick = (link) => {
    setActiveLink(link);
  };
  return (
    <React.Fragment>
      <div className="sidebar-main">
        <div className="container d-flex h-100 flex-column justify-content-between">
          <section className="one" style={{ fontSize: "17px", color: "black" }}>
            <Link to="/dashboard" style={{ marginTop: "30px", color: "black", backgroundColor: activeLink === 'dashboard' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px" }} onClick={() => handleClick('dashboard')}>Dashboard</Link>
            <br />
            <br />
            <Link to="/banner" style={{ marginTop: "30px", color: "black", backgroundColor: activeLink === 'client' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px" }} onClick={() => handleClick('client')}>Banners</Link>
            <br />
            <br />
            <Link to="/offer" style={{ marginTop: "30px", color: "black", backgroundColor: activeLink === 'consultant' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px" }} onClick={() => handleClick('consultant')}>Offers</Link>
            <br />
            <br />
            <Link to="/stores" style={{ marginTop: "30px", color: "black", backgroundColor: activeLink === 'stores' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px" }} onClick={() => handleClick('stores')}>Stores</Link>
            <br />
            <br />
            <Link to="/config" style={{ marginTop: "30px", color: "black", backgroundColor: activeLink === 'config' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px" }} onClick={() => handleClick('config')}>Config</Link>
            <br />
            <br />
            <Link to="/review" style={{ marginTop: "30px", color: "black", backgroundColor: activeLink === 'review' ? 'rgba(255, 182, 25, 0.72)' : '', padding: "10px", borderRadius: "8px" }} onClick={() => handleClick('review')}>Reviews</Link>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(Sidebar);

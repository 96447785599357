import React from "react";
import { Navigate } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/dashboard";

import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import offer from "../pages/Offers"
import banner from "../pages/Banners"
import Store from "../pages/Stores"
import config from "../pages/Config"
import review from "../pages/Reviews"






const userRoutes = [
  { path: "/dashboard", Component: Dashboard },
  { path: "/offer", Component: offer },
  { path: "/banner", Component: banner },
  { path: "/stores", Component: Store },
  { path: "/config", Component: config },
  { path: "/review", Component: review },



  {
    path: "/",
    exact: true,
    Component: () => {

      return <Navigate to="/dashboard" />;

    },
  },
  
];

const authRoutes = [
  { path: "/", Component: Login },
  { path: "/logout", Component: Logout },
  // {
  //   path: "/signup",
  //   Component: () => {
  //     if (localStorage.getItem("token")) {
  //       return <Navigate to="/dashboard" />;
  //     }
  //     return <SignUp />
  //   }
  // },
  {
    path: "/login",
    Component: () => {
      if (localStorage.getItem("token")) {
        return <Navigate to="/dashboard" />;
      }
      return <Login />;
    },
  },
  

];

export { userRoutes, authRoutes };

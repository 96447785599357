import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { doPOST, doGET } from '../util/httpUtil';

const Dashboard = () => {
  const [file, setFile] = useState({
    selectedFile: null
  });
  const [updateRkbMessage, setUpdateRkbMessage] = useState({
    inProgress: false,
    message: ""
  });

  const [uploadProductCSVFile, setUploadProductCSVFile] = useState({
    inProgress: false,
    message: ""
  });

  const handleClick = async () => {
    try {
      await fetch('/api/v1/pushNotification/pendingCart', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          title: 'New Notification',
          message: 'Hello, this is a notification!'
        })
      });
    } catch (error) {
      console.log('Error sending notification:', error);
    }
  };

  const handleWishlistClick = async () => {
    try {
      await fetch('/api/v1/pushNotification/wishlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          title: 'New Notification',
          message: 'Hello, this is a notification!'
        })
      });
    } catch (error) {
      console.log('Error sending notification:', error);
    }
  };

  const handleFileChange = (event) => {
    setFile({
      selectedFile: event.target.files[0],
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { selectedFile } = file;

    if (selectedFile) {

      const formD = new FormData;
      formD.set("file", selectedFile);
      await doPOST('/api/v1/inventory/product/mapping/save', formD);
      console.log('Selected file:', selectedFile);
    } else {
      // Handle no file selected
      console.log('No file selected.');
    }
  };

  const handleupdateRkbInventoryFromDataBaseToShopify = async (event) => {

    setUpdateRkbMessage({
      inProgress: true,
      message: "Inventory processed in Shopify"
    })
    const response = await doPOST('/api/v1/inventory/product/inventory/process');
    const inProgress = response?.data?.inProgress;
    const message = response?.data?.message

    setUpdateRkbMessage({
      inProgress,
      message: inProgress ? message : ""
    })
  };


  const handleUploadsProductCSVFile = async (event) => {
    setUploadProductCSVFile({
      inProgress: true,
      message: "uploading file..."
    })

    const response = await doPOST('/api/v1/inventory/product/inventory/save');
    const inProgress = response?.data?.inProgress;
    const message = response?.data?.message

    setUploadProductCSVFile({
      inProgress,
      message
    })
    console.log(response);
  };

  const rkbInventoryDownloadUrl = async () => {
    const response = await doGET('/api/v1/inventory/product/rkb/download');
    window.location.replace(response?.data?.data);
  };

  const rkbProductsUrl = async () => {
    const response = await doGET('/api/v1/product/import/download');
    const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response?.data?.data);
    const link = document.createElement('a');
    link.href = csvContent;
    link.target = '_blank';
    link.download = 'ShopifyProducts.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Card style={{ position: "relative", left: "15%", top: "100px" }}>
        <CardBody>
          <Button onClick={handleClick}>
            Send Cart Notification
          </Button>
          <Button style={{ marginLeft: "20px" }} onClick={handleWishlistClick}>
            Send WishList Notification
          </Button>

          {/* <Button>
              Send Notification
          </Button> */}
        </CardBody>
      </Card>

      <Card className='py-3  ps-3' style={{ position: "relative", left: "15%", top: "100px" }}>
        <div >
          <p className='text-dark' style={{
            fontSize: "1rem",
            color: "black"
          }}>Download Shopify Products</p>
          <div className='d-flex align-items-center'>
            <Button style={{
              width: 'fit-content',
            }} onClick={rkbProductsUrl}>Download Shopify Products</Button>
          </div>
          <p className='m-0 text-center'>Products downloaded are imported from Shopify</p>
        </div>
      </Card>

      {/* <Card className='py-3  ps-3' style={{ position: "relative", left: "15%", top: "100px" }}>
        <div >
          <p className='text-dark' style={{
            fontSize: "1rem",
            color: "black"
          }}>Download Rkb Inventory</p>
          <div className='d-flex align-items-center'>
            <Button style={{
              width: 'fit-content',
            }} onClick={rkbInventoryDownloadUrl}>Download Inventory File</Button>
          </div>
          <p className='m-0 text-center'>After downloading inventory from here, upload it to FTP server in directory <b>/inventoryupdate/new/web_product.tsv</b></p>
        </div>
      </Card>

      <Card className='d-flex flex-row justify-content-between p-3' style={{ position: "relative", marginLeft: "15%", top: "100px" }}>
        <div >
          <p className='text-dark' style={{
            fontSize: "1rem",
            color: "black"
          }}>Upload Inventory To Self Server</p>
          <div >
            <Button disabled={uploadProductCSVFile?.inProgress} onClick={handleUploadsProductCSVFile} style={{
              width: 'fit-content'
            }}>Upload  Inventory from Server</Button>
            <p className='m-0 text-center' style={uploadProductCSVFile.inProgress ? {color:'green'} : {color:'red'}}>{uploadProductCSVFile?.message}</p>
          </div>
          <p className='m-0 text-center'>After uploading inventory file to FTP server in directory <b>/inventoryupdate/new/web_product.tsv</b> Click on <b>UPLOAD INVENTORY FROM SERVER</b> to upload it to our DATABASE</p>
        </div>
      </Card>

      <Card className='d-flex flex-row justify-content-between p-3' style={{ position: "relative", marginLeft: "15%", top: "100px" }}>
        <div>
          <p className='text-dark' style={{
            fontSize: "1rem",
            color: "black"
          }}>Upload products CSV file</p>
          <form onSubmit={handleSubmit}>
            <input type="file" onChange={handleFileChange} />
            <Button type="submit">Upload File</Button>
          </form>
          <p className='m-0 text-center'>After clicking on <b>UPLOAD INVENTORY FROM SERVER</b> to upload it to our DATABASE, upload csv file of variant wise products to upload it to our DATABASE for product mapping</p>
        </div>
      </Card>

      <Card className='py-3  ps-3' style={{ position: "relative", left: "15%", top: "100px" }}>
        <p className='text-dark' style={{
          fontSize: "1rem",
          color: "black"
        }}>Update Rkb Inventory</p>
        <div className='d-flex align-items-center'>
          <Button disabled={updateRkbMessage?.inProgress} style={{
            width: 'fit-content'
          }} onClick={handleupdateRkbInventoryFromDataBaseToShopify}>Update Rkb</Button>
          <p className='mt-2 ms-3' style={updateRkbMessage.inProgress ? {color:'green'} : {color:'red'}}>{updateRkbMessage?.message}</p>
        </div>
        <p className='m-0 text-center'>Now that all above 3 steps are completed, click on above button to upload inventory to <b>Shopify</b></p>
      </Card> */}
    </div>
  )
}

export default Dashboard
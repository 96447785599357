import { ThreeBounce } from "better-react-spinkit";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/userContext";
import { CountrySelect, InputField } from "../../components";
import { ApiHandler } from "../../store/apiHandler/apiHandler";
import { API_METHODS, ENDPOINTS } from "../../common/utils/Constants";

const Login = (props) => {
  let { isCpanelLogin } = props;
  const [imageURL, setImageUrl] = useState();
  let navigate = useNavigate();
  const loginData = useRef(isCpanelLogin ? {
    email: "",
    password: "",
    captchaQuery: ""
  } : {
    authType: "password",
    countryCode: 91,
    password: "",
    language: "en",
    phone: null,
    email: "",
    emailPhone: "",
  })
  const [isEmail, setIsEmail] = useState(false);
  const { adminLogin, setIsLoggedIn,  setIsAdmin, setAuthToken  } = useContext(UserContext);
  const { showMessage } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(isCpanelLogin ? {
    email: "",
    password: "",
    captchaQuery: ""
  } : {
    authType: "password",
    countryCode: 91,
    password: "",
    language: "en",
    phone: null,
    email: "",
    emailPhone: "",
  });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onLogin = async () => {
    if (loading) return;
    if (!validateEmail(loginData.current.email)) {
      if (isCpanelLogin) {
        showMessage(
          "error",
          "error",
          "Enter a Valid Email"
        );
        return;
      } else
        setData((data) => { return { ...data, phone: loginData.email, email: null } });
      loginData.current = { ...loginData.current, phone: loginData.current.email, email: null }

    }

    try {
      setLoading(true);
      var response = isCpanelLogin ? await cpanelLogin(loginData.current) : await adminLogin(loginData.current);
      if (response.status === 200) {
        navigate("/dashboard", { replace: true });
      } else {
        showMessage(
          "error",
          "Error",
          JSON.stringify(JSON.parse(response?.data).message)
        );
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const getCaptcha = () => {
    let url;
    fetch(ENDPOINTS.cpanelgetCaptcha).then((response) => {
      response.blob().then((blob) => {
        url = window.URL.createObjectURL(blob);
        setImageUrl(url);
      });
    });
  };

  const cpanelLogin = async () => {
    try {
      const response = await ApiHandler({
        reqParam: loginData.current,
        method: API_METHODS.POST,
        endPoint: ENDPOINTS.cpanelLogin,
      });
      if (response.status === 200) {
        setAuthToken(response.data.token);
        setIsLoggedIn(true);

        if (response.data.superadmin == "1") {
          setIsAdmin(true);
        }
      }
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    document.body.className = "authentication-bg";
   
    return function cleanup() {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        {/* <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link> */}
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={6}>
              <Card className='shadow-none'>
                <CardBody className="p-4">
                  <div className="text-center">
                   
                  </div>
                  <div className="text-center mt-2">
                    <p className="text-primary" style={{ fontWeight: 'bold', fontSize: '17px' }}>Sign In</p>
                  </div>
                  <div className="p-2 mt-4">
                    <div className="form-horizontal">
                     

                      <div className="mb-1">
                        <Row>
                          
                          <Col className={isCpanelLogin ? "col-lg-12" : isEmail ? 'col-lg-12' : "col-lg-12"}>
                            <InputField
                              value={data?.email}
                              onChange={(v) => {
                                if (isCpanelLogin) {
                                  loginData.current = { ...loginData.current, email: v }
                                  setData({ ...data, email: v });
                                } else {
                                  loginData.current = { ...loginData.current, email: v, emailPhone: v }
                                  setData({ ...data, email: v, emailPhone: v });
                                }
                              }}
                              // style={{
                              //   height: "40px",
                              // }}
                              required
                              label={ "Email" }
                              placeholder="Enter email"
                              type="text"
                              error={
                                data?.email?.length === 0
                                  ? "Please enter office name"
                                  : ""
                              }
                              // showErr={hasErr}
                              showClear={true}
                            />
                          </Col>
                        </Row>
                      </div>


                      <div className="mb-1">
                        <InputField
                          value={data?.password}
                          onChange={(v) => {  loginData.current = { ...loginData.current, password: v }; setData({ ...data, password: v }) }}
                          required
                          label={"Password"}
                          className="col-12 mt-3"
                          placeholder="Password"
                          type="password"
                          error={
                            data?.password?.length === 0
                              ? "Please enter Password"
                              : ""
                          }
                          // showErr={hasErr}
                          showClear={true}
                        />
                      </div>

                    

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          onClick={onLogin}
                        >
                          {loading ? (
                            <ThreeBounce size={12} color="#fff" />
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </div>
                      {!isCpanelLogin && <div
                        className="mb-1 float-start"
                        style={{ marginTop: "20px" }}
                      >
                      
                      </div>}
                  
                    </div>
                  </div>
                </CardBody>
              </Card>
             
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
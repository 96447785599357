import React, { useEffect, useState, useContext } from 'react'
import { Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';
import { InputField, Layout } from '../components'
import EditLink from '../components/EditLink';
import PencilLink from '../components/PencilLInk';
import { SelectField } from '../components/SelectField/SelectField';
import { DialogContext } from '../store/context/DialogContext';

const Banners = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [newData, setNewData] = useState({})
  const { showMessage } = useContext(DialogContext)

  const { showError } = useContext(DialogContext)
  const fetchData = async () => {
    try {
      const response = await fetch('/api/v1/banners');
      const responseData = await response.json();
      setData(responseData);
    } catch (error) {
      setError(error.message);
    }
  };
  useEffect(() => {


    fetchData();
  }, []);
  const deleteitem = async (id) => {
    try {
      await fetch(`/api/v1/banner/remove/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });
      fetchData();
    } catch (error) {
    }
  };
  const saveData = async (url) => {
    try {
      if (newData?.type && newData?.banner && newData?.handle) {
        await fetch('/api/v1/banner/add', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(newData)
        });
        showMessage("info", "Saved Successfully")
        fetchData();
      }
      else
        showError("All fields Required")

    } catch (error) {
      console.log('Error sending notification:', error);
    }
  };
  const getBanner = async (id) => {
    try {
      const response = await fetch(`/api/v1/banner/${id}`);
      const responseData = await response.json();
      setNewData(responseData);
    } catch (error) {
    }
  }
  const type = [
    {
      label: "Dashboard",
      value: 1,
    },
    {
      label: " Subcategory",
      value: 2,
    }

  ]
  const getimages = (url) => {
    const linksArray = url?.split(',');
    return linksArray
  }
  const getType = (val) => {
    if (val == 1)
      return " Dashboard";
    if (val == 2)
      return " SubCategory"
  }
  return (
    <div>
      <Card style={{ left: "15%", top: "70px", maxWidth: "1320px" }}>
        <CardBody>
          <Button className='col-sm-1 ' style={{ position: "absolute", right: "20px" }} onClick={() => setNewData({})}>
            Add new
          </Button>
          <Row className='mt-4'>
            <Col className="col-sm-2">
              <Label>
                Enter  Type
              </Label>
              <SelectField data={type} placeholder="Enter type" value={newData?.type} onChange={(v) => setNewData({ ...newData, type: v.value })} />
            </Col>
            <Col className="col-sm-6">
              <Label>
                Enter  Url
              </Label>
              <InputField placeholder="Enter Url" value={newData?.banner} onChange={(v) => setNewData({ ...newData, banner: v })} />
            </Col>
            <Col className="col-sm-2">
              <Label>
                Enter Handle
              </Label>
              <InputField placeholder="Enter Handle" value={newData?.handle} onChange={(v) => setNewData({ ...newData, handle: v })} />
            </Col>
            <Button className='col-sm-1 mt-4 m-2 ' onClick={saveData} style={{ height: "35px" }}>
              Save Url
            </Button>
          </Row>

        </CardBody>
      </Card>
      <Layout>
        <Layout.TheadFull>
          <Thead className="thead-dark">
            <Tr

            >
              <Th>Image</Th>
              <Th>Url</Th>
              <Th>Type</Th>
              <Th>Handle</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
        </Layout.TheadFull>
        <Layout.TbodyFull>
          <Tbody>
            {data?.map((ele, id) => (
              <Tr key={id}
              >
                <Td>
                  {getimages(ele?.banner)?.map((link, index) => (
                    <div className='mb-4'>
                      <img src={link} key={index} height="100" />
                    </div>
                  ))}
                </Td>
                <Td>
                  {getimages(ele?.banner)?.map((link, index) => (
                    <div className='mt-4 pt-4'>
                      {link}
                    </div>
                  ))}
                </Td>
                <Td>
                  {getType(ele?.type)}
                </Td>
                <Td>
                  {ele?.handle}
                </Td>
                <Td className="float-start">
                  <EditLink onClick={() => deleteitem(ele?._id)} />
                  <PencilLink onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    getBanner(ele?._id)
                  }} />
                </Td>
              </Tr>
            ))
            }
          </Tbody>
        </Layout.TbodyFull>
      </Layout>
    </div>
  )
}

export default Banners
import { useNavigate } from "react-router-dom";

const PencilLink = (props) => {
  let navigate = useNavigate();
  return (
    <div
      className={props.className + " mx-2"}
      style={{ display: "inline-block", color: "primary", cursor: "pointer" }}
      onClick={() => {
        if (props?.to) navigate(props?.to);
        props?.onClick();
      }}
    >
      <i
        style={{ color: "primary" }}
        className="uil uil-pen color-primary font-size-18"
      />
    </div>
  );
};
export default PencilLink;
